<template>
  <b-card-code>
     <div class="mb-2" style="z-index:1">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select v-model="pageLength" :options="['5', '10', '20', '50', '100', '500']" class="mx-1"
                @input="getData()" />
              <span class="text-nowrap">{{ paginationStart }} to {{ paginationEnd }} of {{ paginationTotal
              }}</span>
            </div>
            <div>
              <b-pagination @input="getData()"
                v-model="paginationValue" :total-rows="paginationTotal" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
                <!-- @input="(value) => props.pageChanged({ currentPage: value })" -->
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </div>
  </b-card-code>
</template>
<script>
import BCardCode from "@core/components/b-card-code";
import {
  BFormCheckbox,
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BImgLazy,
  BTable,
  BPagination,
  BFormSelect
} from "bootstrap-vue";
import axios from '@/components/axios';

export default {
  components: {
    BFormCheckbox,
    BFormFile,
    BCardCode,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BImgLazy,
    BTable,
    BPagination,
    BFormSelect
  },
  data() {
    return {
      alldata: [],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
       paginationValue: 1,
      paginationTotal: 0,
      pageLength: 10,
      paginationStart: 0,
      paginationEnd: 0,
    };
  },
  async mounted() {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
    this.getData();
  },
  methods: {
    async getData() {
        const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/bankledger?pagenum=${this.paginationValue}&pagesize=${this.pageLength}`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.alldata = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    }
  },
};
</script>
